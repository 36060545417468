import React, { useState, useCallback } from 'react';
import RenderProjectLine from './components/RenderProjectLine';
import styled from 'styled-components';
import useFilteredAndOrderedDeploymentList, {
  ToggleFilterCheckbox,
} from './useFilteredAndOrderedDeploymentList';

const ButtonHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background: #f1f1f1;
`;
const ConfigButton = styled.button`
  color: #fff;
  border-radius: 3px;
  background-color: #2a94cf;
  padding: 10px 15px;
  border: none;
  font-weight: 600;
`;
const CheckFeaturesButton = styled(ConfigButton)`
  margin-left: 15px;
`;

export default function Deployments({ deployments }) {
  const [showConfiguration, showConfigurationSet] = useState(true);
  const [showCheckFunctions, showCheckFunctionsSet] = useState(false);

  const toggleShowConfiguration = useCallback(() => {
    showConfigurationSet(!showConfiguration);
  }, [showConfiguration]);

  const toggleShowCheckFunctions = useCallback(() => {
    showCheckFunctionsSet(!showCheckFunctions);
  }, [showCheckFunctions]);

  // console.log('deployments', deployments)

  const { filteredDeploymentList: deploymentsOrderedFiltered, setFilterOnKind } =
    useFilteredAndOrderedDeploymentList(deployments);

  return (
    <>
      <ButtonHeader>
        <ConfigButton type="button" onClick={toggleShowConfiguration}>
          {showConfiguration ? 'Config hide' : 'Config show'}
        </ConfigButton>{' '}
        <CheckFeaturesButton type="button" onClick={toggleShowCheckFunctions}>
          {showCheckFunctions ? 'Check features hide' : 'Check features show'}
        </CheckFeaturesButton>
        <ToggleFilterCheckbox
          name="show-inopai-dev"
          label="also show inopai dev installations"
          kind="inopai-dev"
          setFilterOnKind={setFilterOnKind}
        />
        <ToggleFilterCheckbox
          name="show-deployment"
          label="also show other installations"
          kind="deployment"
          setFilterOnKind={setFilterOnKind}
        />
      </ButtonHeader>
      <div>
        {deploymentsOrderedFiltered.map((project, index) => (
          <RenderProjectLine
            index={index + 1}
            key={project.path_with_namespace}
            project={project}
            showConfiguration={showConfiguration}
            showCheckFunctions={showCheckFunctions}
          />
        ))}
      </div>
    </>
  );
}
