import { useMemo } from 'react';
import useFetch from './useFetch';

const EXCLUDES = [
  'portal.debtify.com',
  'mvpv1.debtify.com',
  'inobasmanuelleerfassungui.kubernetes.netsyno.com',
  'demo.kubernetes.netsyno.com',
  'bmelstadtlanddu.inopai.com',
  'appaxelera.kubernetes.netsyno.com',
  'appaxelerabeta.kubernetes.netsyno.com',
  'inopaidmdev.inopai.com',
  'inopaidmdev.kubernetes.netsyno.com',
  'dev.schnelltest.inopai.com',
  'portaldebtifycombeta.kubernetes.netsyno.com',
  'inopaibmelstadtlanddudev.kubernetes.netsyno.com',
  'venturedevcombeta.inopai.com',
  'staging.bmel.34i.de',
];

export default function useFetchDeployments() {
  const { data, error } = useFetch('/api/deployments');

  const filtereddata = useMemo(() => {
    if (!data) {
      return data;
    }
    return data.filter((ent) => {
      if (EXCLUDES.includes(ent.installationdomain)) {
        return false;
      }
      return true;
    });
  }, [data]);

  return { filtereddata, error };
}
