import React, { useMemo } from 'react';
import styled from 'styled-components';

// import ShowConf from './ShowConf';
import useProjectProps from '../hooks/useProjectProps';
// import LinkWithToken from './LinkWithToken';
import QueryLogsRenderSparkline from './QueryLogsRenderSparkline';
import getActionlogQueryParams from '../utils';
import { useInopaiActionlogs } from '../hooks/useInopaiActionlogs';
import ProjectHeaderIcons from './ProjectHeaderIcons';
import QuickStatusSummary from './QuickStatusSummary';
import { useProjectQuickStatus } from '../hooks/useProjectQuickStatus';

const ProjectHeaderIconsHolder = styled.div`
  margin-left: 5px;
`;

// const ConfHolder = styled.div`
//   font-size: 15px;
//   padding: 10px 15px 5px 15px;
//   background: #f1f1f1;
//   border-radius: 3px;
//   margin: 10px 0;
//   border: none;
// `;

// const ShowDetailsButton = styled.button`
//   color: #fff;
//   width: 110px;
//   border-radius: 3px;
//   background-color: #2a94cf;
//   padding: 10px 5px;
//   border: none;
//   font-weight: normal;
//   margin-left: 10px;
// `;

// const InstallInfoHolder = styled.div`
//   display: flex;
// `;

// const LinkStyled = styled(LinkWithToken)`
//   text-decoration: none;
//   color: #000;
// `;

function Lastupdate({ date }) {
  const style = useMemo(() => {
    const monthLastupdate = new Date(date).getMonth();
    const monthToday = new Date().getMonth();
    const xMonthAgo =
      monthToday >= monthLastupdate
        ? monthToday - monthLastupdate
        : 12 - monthLastupdate + monthToday;

    // console.log('month', monthLastupdate, monthToday, 'xMonthAgo', xMonthAgo);

    if (xMonthAgo > 0) {
      return { background: 'pink' };
    }
    return {};
  }, [date]);

  return <div style={style}>Update {date}</div>;
}

const PG_LATEST_VERSION = "15.3-alpine3.18"
const ES_LATEST_VERSION = '8.8.0';
const REDIS_LATEST_VERSION = '6.0.19-alpine3.18';

function EsVersion({ elasticsearchversion }) {
  if (!elasticsearchversion) {
    return null;
  }
  if (elasticsearchversion === ES_LATEST_VERSION) {
    return null;
  }
  return <div>es{elasticsearchversion}</div>;
}

function PgVersion({ pgversion }) {
  if (pgversion === PG_LATEST_VERSION) {
    return null;
  }
  return <div>pg{pgversion}</div>;
}

function RedisVersion({ redisversion }) {
  if (redisversion === REDIS_LATEST_VERSION) {
    return null;
  }
  return <div>redis{redisversion}</div>;
}

export default function RenderProjectLine({ index, project, showConfiguration, showCheckFunctions }) {
  const { kubectllink, projUrl } = useProjectProps(project);
  const { status, messages, // tooltip, 
  } = useProjectQuickStatus(projUrl);
  // console.log('status', status);

  const actionlogparams = useMemo(() => getActionlogQueryParams(7), []);
  const actionlogsresp = useInopaiActionlogs(
    project.kind,
    projUrl,
    'day',
    actionlogparams.startdate,
    actionlogparams.enddate
  );

  const connectionOk = useMemo(() => status && messages && messages.connection !== '{}', [status, messages])
  // console.log('project.configurationenv', project.configurationenv)

  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: '1fr 150px 150px 150px',
      gridGap: '.2rem',
      border: '1px solid pink',
      marginBottom: '4px',
    }}>
      <ProjectHeaderIconsHolder>
        {index}{' '}
        <QuickStatusSummary status={status} messages={messages} />
        <ProjectHeaderIcons project={project} kubectllink={kubectllink} projUrl={projUrl} />
      </ProjectHeaderIconsHolder>
      {/* <ShowConf conf={project.deployvariablesjson} label="deployvariablesjson" /> */}
      <div>
        {project.configurationenv &&
          project.configurationenv.EMAIL_BACKEND === 'django.core.mail.backends.smtp.EmailBackend' ? (
          <div style={{ color: 'red' }}>PROD</div>
        ) : null}
        {project.configurationenv &&
          project.configurationenv.EMAILREPLY_ENABLE ? (
          <div style={{ color: 'orange' }}>EMAILREPLY</div>
        ) : null}
        {project.configurationenv &&
          project.configurationenv.IO_CREATE_ACTIVITY === "0" && project.configurationenv.IO_UPDATE_ACTIVITY === "0" ? (
          <span style={{ color: 'blue' }}>activities_deactivated</span>
        ) : null}
        {project.deployvariablesjson ? <EsVersion elasticsearchversion={project.deployvariablesjson.elasticsearchversion} /> : null}
        <PgVersion pgversion={project.postgres_default_image_version} />
        <RedisVersion redisversion={project.redis_default_image_version} />
      </div>
      {project.deployvariablesjson && project.deployvariablesjson.date ? (
        <Lastupdate date={project.deployvariablesjson.date} />
      ) : <div />}
      {connectionOk ? <QueryLogsRenderSparkline actionlogsresp={actionlogsresp} /> : null}
    </div>
  );
}

// {/* {false && project.postgres_backups_storage ? (
//             <div>
//               Speicher: pg{project.postgres_backups_storage}, es{project.elasticsearch_storage},
//               media{project.backend_media_storage}
//             </div>
//           ) : null} */}